import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import cn from 'classnames';

import ButtonDrip from '@app/components/ui/button/button-drip';
import ButtonLoader from '@app/components/ui/button/button-loader';
import { LoaderSizeTypes, LoaderVariantTypes } from '@app/components/ui/loader';

type ColorNames = 'primary' | 'white' | 'gray' | 'success' | 'info' | 'warning' | 'danger';
type SizeNames = 'large' | 'small';

const colors: Record<ColorNames, string> = {
    primary: '!text-white bg-blue hover:bg-blue-6',
    white: 'text-gray-900 bg-white focus:ring-white',
    gray: 'text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-gray-100',
    success: 'text-green-500 bg-green-500 hover:bg-green-200 focus:ring-green-500',
    info: 'text-blue-500 bg-blue-500 hover:bg-blue-200 focus:ring-blue-500',
    warning: 'text-yellow-500 bg-yellow-500 hover:bg-yellow-200 focus:ring-yellow-500',
    danger: 'text-red-500 bg-red-500 hover:bg-red-200 focus:ring-red-500'
};
const sizes: Record<SizeNames, string> = {
    large: 'large-button',
    small: 'small-button'
};

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    disabled?: boolean;

    color?: ColorNames;
    size?: SizeNames;
    fullWidth?: boolean;
    loaderSize?: LoaderSizeTypes;
    loaderVariant?: LoaderVariantTypes;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, className, isLoading, disabled, fullWidth, color = 'primary', size = 'small', loaderSize = 'small', loaderVariant = 'scaleUp', onClick, ...buttonProps }, ref: React.Ref<HTMLButtonElement | null>) => {
        let [dripShow, setDripShow] = useState<boolean>(false);
        let [dripX, setDripX] = useState<number>(0);
        let [dripY, setDripY] = useState<number>(0);
        const colorClassNames = colors[color];
        const sizeClassNames = sizes[size];
        const buttonRef = useRef<HTMLButtonElement>(null);
        useImperativeHandle(ref, () => buttonRef.current);

        function dripCompletedHandle() {
            setDripShow(false);
            setDripX(0);
            setDripY(0);
        }

        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (!isLoading && buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                setDripShow(true);
                setDripX(event.clientX - rect.left);
                setDripY(event.clientY - rect.top);
            }
            onClick && onClick(event);
        };

        let buttonColorClassNames = '';
        let buttonDripColor = '';

        return (
            <button
                ref={buttonRef}
                onClick={clickHandler}
                className={cn(
                    'relative inline-flex shrink-0 items-center justify-center overflow-hidden rounded-[4px] text-center outline-none transition-all',
                    !disabled ? buttonColorClassNames : 'cursor-not-allowed opacity-50',
                    disabled || isLoading ? '' : ' focus:outline-none',
                    isLoading && 'pointer-events-auto cursor-default focus:outline-none',
                    fullWidth && 'w-full',
                    sizeClassNames,
                    colorClassNames,
                    className
                )}
                disabled={disabled}
                {...buttonProps}
            >
                <span className={cn(isLoading && 'invisible opacity-0')}>{children}</span>

                {isLoading && <ButtonLoader size={loaderSize} variant={loaderVariant} />}

                {dripShow && <ButtonDrip x={dripX} y={dripY} color={['white', 'gray'].indexOf(color) !== -1 ? 'rgba(0, 0, 0, 0.1)' : buttonDripColor} fullWidth={fullWidth} onCompleted={dripCompletedHandle} />}
            </button>
        );
    }
);

Button.displayName = 'Button';
export default Button;
