import { atom, useAtom } from 'jotai';

export type UPGRADE_MODAL_VIEW = 'CONTACT_US' | '';

const modalAtom = atom({ isOpen: false, modalProps: null, view: '' });

export function useFullScreenModal() {
    const [state, setState] = useAtom(modalAtom);
    const openModal = (view: UPGRADE_MODAL_VIEW, modalProps: any = null) =>
        setState({
            ...state,
            isOpen: true,
            modalProps,
            view: view
        });
    const closeModal = () => {
        setState({ ...state, isOpen: false });
        setTimeout(() => {
            setState({ ...state, view: '', isOpen: false, modalProps: null });
        }, 500);
    };

    return {
        ...state,
        openModal,
        closeModal
    };
}
