import { ContactUsDto } from '@app/models/dtos/strapi';
import environments from '@app/configs/environments';

export async function submitForm(requestBody: ContactUsDto) {
    try {
        const res = await fetch(environments.SUBSCRIPTION_HOST_URL + '/api/new-subscriber', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });
        return res.status === 200;
    } catch (e) {
        return false;
    }
}
