import React from 'react';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';

import { CacheProvider, css, EmotionCache } from '@emotion/react';
import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '@app/assets/css/globals.css';
import 'swiper/css';
import 'swiper/css/pagination';

import { NextPageWithLayout } from '@app/types';
import createEmotionCache from '@app/configs/createEmotionCache';
import { usePreserveScroll } from '@app/lib/hooks/use-preserve-scroll';
import MuiThemeProvider from '@app/layouts/_mui-theme-provider';
import NextNProgress from '@app/components/ui/nprogress';
import { appWithTranslation } from 'next-i18next';
import FullScreenModalContainer from '@app/components/modal-views/full-screen-modal-container';
import globalConstants from '@app/constants/global';
import { NextSeo } from 'next-seo';
import { IWhoWeAreProps } from '@app/models/props/who-we-are';
import { ApiResponse, BannerAttributes, CareerAttributes, ItemDto, ProductAttributes, ServiceAttributes, TeamPageAttributes, VacancyDto, WhoWeAreAttributes } from '@app/models/dtos/strapi';
import environments from '@app/configs/environments';
import { ITeamsProps } from '@app/pages/teams';
import { IOurServicesProps } from '@app/pages/our-services';
import { ourService } from '@app/constants/services';
import { ICareerProps } from '@app/pages/career';
import { IProductProps } from '@app/pages/product/[name]';
import { IVacancyProps } from '@app/pages/career/[position]';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    emotionCache?: EmotionCache;
    pageProps: IWhoWeAreProps | ITeamsProps | IOurServicesProps | ICareerProps | IProductProps | IVacancyProps | any;
};

function MainApp({ Component, pageProps, router, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? ((page: any) => page);
    // default
    let title = globalConstants.title || globalConstants.socialPreview.title;
    let description = globalConstants.socialPreview.desc;
    let url = globalConstants.socialPreview.url;
    let imageUrl = globalConstants.socialPreview.image;

    // who-we-are
    const whoWeAre: ApiResponse<WhoWeAreAttributes> | null = pageProps?.whoWeAre;
    if (whoWeAre) {
        title = 'Who We Are';
        description = whoWeAre?.attributes.description ?? description;
        url = url + router.pathname;
        imageUrl = environments.STRAPI_HOST_URL + whoWeAre?.attributes.featureImage.data?.attributes.url ?? imageUrl;
    }
    // our services
    const services: Array<ApiResponse<ServiceAttributes>> | null = pageProps?.services;
    const servicePage: ApiResponse<ItemDto> | null = pageProps?.servicePage;
    const banner: ApiResponse<BannerAttributes> | null = pageProps?.banner;
    if (services && !banner && servicePage) {
        title = ourService.title;
        description = servicePage.attributes.description;
        url = url + router.pathname;
        imageUrl = environments.STRAPI_HOST_URL + services[0].attributes.image.data?.attributes.url ?? imageUrl;
    }
    // career
    const career: ApiResponse<CareerAttributes> | null = pageProps?.career;
    if (career) {
        title = 'Career';
        description = career.attributes.description ?? description;
        url = url + router.pathname;
        imageUrl = environments.STRAPI_HOST_URL + career.attributes.featureImage.data?.attributes.url ?? imageUrl;
    }
    // teams
    const teamsPageDetails: ApiResponse<TeamPageAttributes> | null = pageProps?.teamsPageDetails;
    if (teamsPageDetails) {
        title = 'Teams';
        description = teamsPageDetails.attributes.description ?? description;
        url = url + router.pathname;
    }
    // product
    const product: ProductAttributes | null = pageProps?.product;
    if (product) {
        title = product.name ?? title;
        description = product.description ?? description;
        url = url + `/product/${product.name}`;
        if (product.productImage && !('data' in product.productImage)) {
            imageUrl = environments.STRAPI_HOST_URL + product.productImage?.url ?? imageUrl;
        }
    }

    // vacancy
    const vacancy: VacancyDto | null = pageProps?.vacancy;
    if (vacancy) {
        title = vacancy?.position ?? title;
        description = vacancy?.description ?? description;
        url = url + `/career/${vacancy.position}`;
    }

    usePreserveScroll();

    return (
        <ThemeProvider attribute="class" enableSystem={false} forcedTheme="light" defaultTheme="light">
            <CacheProvider value={emotionCache}>
                <MuiThemeProvider>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <GlobalStyles
                        styles={css`
                            :root {
                                body {
                                    background-color: #f2f7ff;
                                    color: #121212;
                                }
                            }

                            [data-theme='dark'] {
                                body {
                                    background-color: #121212;
                                    color: #fff;
                                }
                            }
                        `}
                    />
                    <NextSeo
                        title={title || globalConstants.socialPreview.title}
                        description={description}
                        // noindex={!environments.IS_IN_PRODUCTION_MODE}
                        // nofollow={!environments.IS_IN_PRODUCTION_MODE}
                        openGraph={{
                            type: 'website',
                            locale: 'en_IE',
                            url,
                            site_name: title || globalConstants.appName,
                            description: description,
                            title,
                            images: [
                                {
                                    url: imageUrl,
                                    alt: title ?? 'Sireto Technology'
                                }
                            ]
                        }}
                        twitter={{
                            handle: globalConstants.twitterHandle,
                            site: url,
                            cardType: 'summary_large_image'
                        }}
                    />
                    {/* <CookieConsent /> */}
                    <NextNProgress color="#0764EB" startPosition={0} stopDelayMs={400} height={2} options={{ easing: 'ease' }} />
                    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover={false} theme="dark" /> {/* <Provider store={store}> */}
                    {getLayout(<Component {...pageProps} key={router.asPath} />)}
                    <FullScreenModalContainer />
                    {/* </Provider> */}
                </MuiThemeProvider>
            </CacheProvider>
        </ThemeProvider>
    );
}

export default appWithTranslation(MainApp);
