import ContactUs from '@app/components/modal-views/modals/contact-us';
import { UPGRADE_MODAL_VIEW, useFullScreenModal } from '@app/components/modal-views/full-screen-modal-context';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '@app/components/ui/button';
import Close from '@app/components/icons/close';

function renderModalContent(view: UPGRADE_MODAL_VIEW | string, modalProps: any) {
    switch (view) {
        case 'CONTACT_US':
            return <ContactUs {...modalProps} />;
        default:
            return <></>;
    }
}

export default function FullScreenModalContainer() {
    const router = useRouter();
    const { isOpen, closeModal, modalProps, view } = useFullScreenModal();

    useEffect(() => {
        // close search modal when route change
        router.events.on('routeChangeStart', closeModal);
        return () => {
            router.events.off('routeChangeStart', closeModal);
        };
    }, []);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-[2500] h-full w-full overflow-y-auto overflow-x-hidden  text-center" onClose={closeModal}>
                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Dialog.Overlay className="fixed inset-0 z-40 cursor-pointer bg-gray-700 bg-opacity-60 backdrop-blur" />
                </Transition.Child>

                {/* This element is need to fix FocusTap headless-ui warning issue */}
                <div className="sr-only">
                    <Button onClick={closeModal} className="opacity-50 hover:opacity-80 ">
                        <Close />
                    </Button>
                </div>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in duration-500"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-100 translate-y-full"
                >
                    <div data-testid="modal-view" className="relative  z-50 inline-block h-full w-full text-left">
                        {view && renderModalContent(view, modalProps)}
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}
