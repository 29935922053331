import environments from '@app/configs/environments';

const globalConstants = {
    appName: 'Sireto Technology',
    appDesc: 'Driving Innovation Through Blockchain and Software Development',
    title: 'Sireto Technology',
    favIcon: 'favIcon',
    twitterHandle: '@SiretoTech',
    titleImg: 'titleImg',
    socialPreview: {
        url: `https://sireto.io`,
        title: 'Sireto Technology',
        desc: 'Driving Innovation Through Blockchain and Software Development',
        image: `/sireto-logo.png`
    },
    consoleWarningTitle: `%cStop!`,
    consoleWarningDescription: `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your sensitive information.`,
    imageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']
};

export default globalConstants;
