import React from 'react';

function Close(Props: React.SVGAttributes<{}>) {
    return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...Props}>
            <path d="M1 1L38 38" stroke="#242424" />
            <path d="M38 1L1 38" stroke="#242424" />
        </svg>
    );
}

export default Close;
