import getConfig from 'next/config';

const config = getConfig();
let publicRuntimeConfig: any = {};
let serverRuntimeConfig: any = {};
if (config && config.publicRuntimeConfig) {
    publicRuntimeConfig = config.publicRuntimeConfig;
}

if (config && config.serverRuntimeConfig) {
    serverRuntimeConfig = config.serverRuntimeConfig;
}

const IS_IN_PRODUCTION_MODE = publicRuntimeConfig.NEXT_PUBLIC_NODE_ENV === 'production';
const BASE_DEPLOY_PATH = process.env.BASE_DEPLOY_PATH ?? '';

const environments = {
    // build-time configs
    BASE_DEPLOY_PATH,
    IS_IN_PRODUCTION_MODE,
    NEXT_PUBLIC_NODE_ENV: publicRuntimeConfig.NEXT_PUBLIC_NODE_ENV,
    HTTP_SCHEME: publicRuntimeConfig.HTTP_SCHEME || 'https://',
    GHOST_ADMIN_API_KEY: publicRuntimeConfig.GHOST_ADMIN_API_KEY,
    GHOST_HOST_URL: publicRuntimeConfig.GHOST_HOST_URL,
    GHOST_CONTENT_API_KEY: publicRuntimeConfig.GHOST_CONTENT_API_KEY,
    STRAPI_TOKEN: publicRuntimeConfig.STRAPI_TOKEN,
    STRAPI_HOST_URL: publicRuntimeConfig.STRAPI_HOST_URL,
    STRAPI_API_TOKEN: publicRuntimeConfig.STRAPI_API_TOKEN,
    VIRTUAL_HOST: publicRuntimeConfig.VIRTUAL_HOST,
    RECAPTCHA_SITE_KEY: publicRuntimeConfig.RECAPTCHA_SITE_KEY,
    SUBSCRIPTION_HOST_URL: publicRuntimeConfig.SUBSCRIPTION_HOST_URL,
    NEXT_PUBLIC_GOOGLE_ANALYTICS: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    ANONYMIZE_PRO_DEMO_LINK: publicRuntimeConfig.ANONYMIZE_PRO_DEMO_LINK,
    HOST_URL: publicRuntimeConfig.HOST_URL,
    ANONYMIZE_PRO_BACKEND_URL: publicRuntimeConfig.ANONYMIZE_PRO_BACKEND_URL
};

export default environments;
